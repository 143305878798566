<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">分销管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back(-1)">我的分销</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">查看卡密</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 5px">
            <div title="卡密" class="searchboxItem">
              <span class="itemLabel">卡密:</span>
              <el-input
                v-model="retrievalData.keyPwd"
                type="text"
                size="small"
                style="width: auto"
                placeholder="请输入卡密"
                clearable
              />
            </div>
            <div title="兑换人姓名" class="searchboxItem">
              <span class="itemLabel">兑换人姓名:</span>
              <el-input
                v-model="retrievalData.customerName"
                type="text"
                size="small"
                style="width: auto"
                placeholder="请输入兑换人姓名"
                clearable
              />
            </div>
            <div title="兑换人手机号" class="searchboxItem">
              <span class="itemLabel">兑换人手机号:</span>
              <el-input
                v-model="retrievalData.mobile"
                type="text"
                size="small"
                style="width: auto"
                placeholder="请输入兑换人手机号"
                clearable
              />
            </div>
            <div title="是否已发送学员" class="searchboxItem">
              <span class="itemLabel">是否已发送学员:</span>
              <el-select
                clearable
                size="small"
                v-model="retrievalData.send"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in openList2"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox" style="padding-top: 10px">
            <div title="状态" class="searchboxItem">
              <span class="itemLabel">状态:</span>
              <el-select
                clearable
                size="small"
                v-model="retrievalData.keyState"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in openList1"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div>
              <el-button class="bgc-bv" @click="getData()">查询</el-button>
              <el-button class="bgc-bv" @click="dialogVisible2 = true"
                >生成卡密</el-button
              >
              <el-button class="bgc-bv" @click="doExportkmi()"
                >批量导出卡密</el-button
              >
              <!-- <el-button class="bgc-bv" @click="doExportkmian()"
                >批量导出卡面</el-button
              > -->
            </div>
          </div>
        </div>
        <div class="remark">注：请按需生成卡密，卡密一旦生成，不可更改。请手动标记“是否已发放学员列”，可记录卡密发放情况。</div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
            >
              <el-table-column label="序号" align="center" type="index" />
              <el-table-column
                label="卡密"
                align="center"
                prop="keyPwd"
                show-overflow-tooltip
              />
              <el-table-column
                label="兑换人姓名"
                align="center"
                prop="customerName"
                show-overflow-tooltip
              />
              <el-table-column
                label="兑换人手机号"
                align="center"
                prop="mobile"
              />
              <el-table-column
                label="兑换日期"
                align="center"
                prop="useTime"
              />
              <el-table-column
                label="状态"
                align="center"
                prop="keyStateName"
              />
              <el-table-column label="是否已发放学员" align="center">
                <template slot-scope="scope">
                  <el-switch
                    v-model="scope.row.send"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    @change="ynffxy(scope.row)"
                  >
                  </el-switch>
                  <span style="vertical-align: middle; margin-left: 4px">{{
                    scope.row.send ? "是" : "否"
                  }}</span>
                </template>
              </el-table-column>
              <!-- <el-table-column
                label="操作"
                align="center"
                show-overflow-tooltip
                min-width="160"
                fixed="right"
              >
                <template slot-scope="scope">
                  <el-button
                    style="padding: 0 5px"
                    type="text"
                    size="mini"
                    @click="bdPhone(scope.row)"
                    >绑定用户</el-button
                  >
                  <el-button
                    style="padding: 0 5px"
                    type="text"
                    size="mini"
                    @click="hExportkmian(scope.row)"
                    >导出卡面</el-button
                  >
                </template>
              </el-table-column> -->
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData1"
        />
      </div>
    </div>
    <!-- 生成卡密的弹框 -->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible2"
      :before-close="tradeSetqx"
      width="30%"
    >
      <el-form
        :model="generateCardSecretData"
        :rules="rules"
        ref="generateCardSecretData"
        label-width="120px"
        class="demo-ruleForm"
      >
        <el-form-item
          label="生成数量"
          prop="generateCardSecret"
          style="width: 300px"
        >
          <el-input
            v-model="generateCardSecretData.generateCardSecret"
            size="small"
            placeholder="请输入生成数量"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="remark">注：请按需生成卡密，卡密一旦生成，不可更改。</div>
      <span slot="footer" class="dialog-footer">
        <el-button
          class="bgc-bv"
          size="small"
          round
          style="padding: 8px 30px"
          @click="dialogVisible2 = false"
          >取 消</el-button
        >
        <el-button
          type="primary"
          class="bgc-bv"
          round
          size="small"
          style="padding: 8px 30px"
          @click="generateCardSecretDataYes('generateCardSecretData')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 绑定学员的弹框 -->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible1"
      :before-close="tradeSetqx"
      width="30%"
    >
      <el-form
        :model="tradeSet"
        :rules="rules"
        ref="tradeSet"
        label-width="120px"
        class="demo-ruleForm"
      >
        <el-form-item label="用户手机号" prop="bdPhone" style="width: 300px">
          <el-input
            v-model="tradeSet.bdPhone"
            size="small"
            placeholder="请输入用户手机号"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          class="bgc-bv"
          size="small"
          round
          style="padding: 8px 30px"
          @click="bdPhoneNo"
          >取 消</el-button
        >
        <el-button
          type="primary"
          class="bgc-bv"
          round
          size="small"
          style="padding: 8px 30px"
          @click="bdPhoneYes('tradeSet')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "carmichaelInfo",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    // 自定义校验 - 手机号
    let phone = (rule, value, callback) => {
      const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
      if (reg.test(value)) {
        callback();
      } else {
        return callback(new Error("请输入正确的手机号!"));
      }
    };
    return {
      // 订单id
      orderId: "",
      // 课程id
      courseId: "",
      // 临时数据 - 调接口需要传的id
      temporaryData: {
        keyId: "", // 卡密id
        walletId: "", // 卡包id
      },
      // 检索数据
      retrievalData: {
        keyPwd: "", // 卡密
        customerName: "", // 兑换人姓名
        mobile: "", // 兑换人手机号
        send: "", // 是否已发送学员
        keyState: "", // 状态
      },
      // 状态
      openList1: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "未使用",
          value: "10",
        },
        {
          label: "已使用",
          value: "20",
        },
        {
          label: "已过期",
          value: "30",
        },
        {
          label: "闲置中",
          value: "40",
        },
      ],
      // 是否已发送学员
      openList2: [
        {
          label: "全部",
          value: null,
        },
        {
          label: "是",
          value: 1,
        },
        {
          label: "否",
          value: 0,
        },
      ],
      // 绑定用户的弹窗
      dialogVisible1: false,
      // 绑定用户弹框数据
      tradeSet: {
        bdPhone: "", // 用户手机号
      },
      // 生成卡密的弹窗
      dialogVisible2: false,
      // 生成卡密弹框数据
      generateCardSecretData: {
        generateCardSecret: "", // 分销价格
      },
      // 校验
      rules: {
        // 手机号码
        bdPhone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { validator: phone, trigger: "blur" },
        ],
        // 生成数量
        generateCardSecret: [
          { required: true, message: "请输入生成数量", trigger: "blur" },
        ],
      },
    };
  },
  computed: {},
  created() {
    // 获取上一页面传过来的订单id
    this.orderId = this.$route.query.orderId;
    this.courseId = this.$route.query.courseId;
  },
  methods: {
    // 获取卡密列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        orderId: this.$route.query.orderId, // 订单id
        keyPwd: this.retrievalData.keyPwd, // 卡密
        customerName: this.retrievalData.customerName, // 兑换人姓名
        mobile: this.retrievalData.mobile, // 兑换人手机号
        send: this.retrievalData.send, // 是否已发送学员
        keyState: this.retrievalData.keyState, // 状态
      };
      this.doFetch(
        {
          url: "/biz/card/wallet/queryMiniappList",
          params,
          pageNum,
        },
        true,
        2
      );
    },
    // 生成卡密 - 确定
    generateCardSecretDataYes(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$post(
            "/biz/card/wallet/insertMiniapp",
            {
              courseId: this.courseId,
              orderId: this.orderId,
              issuedNum: this.generateCardSecretData.generateCardSecret
            },
            3000,
            true,
            2
          ).then((res) => {
            if (res.status == 0) {
              this.getData();
              this.generateCardSecretData.generateCardSecret = "";
              this.dialogVisible2 = false;
              this.$message({
                message: "生成成功!",
                type: "success",
              });
            }
          });
        }
      });
    },
    // 批量导出 - 卡密
    doExportkmi() {
      const params = {
        orderId: this.$route.query.orderId, // 订单id
        keyPwd: this.retrievalData.keyPwd, // 卡密
        customerName: this.retrievalData.customerName, // 兑换人姓名
        mobile: this.retrievalData.mobile, // 兑换人手机号
        send: this.retrievalData.send, // 是否已发送学员
        keyState: this.retrievalData.keyState, // 状态
      };
      this.$post(
        "/biz/card/wallet/exportListMiniapp",
        params,
        3000,
        true,
        2
      ).then((res) => {
        window.open(res.data);
      });
    },
    // 批量导出 - 卡面
    doExportkmian() {
      const params = {
        orderId: this.orderId,
        productId: this.courseId,
      };
      this.$post(
        "/biz/card/wallet/batchDownloadMiniappCard",
        params,
        3000,
        true,
        2
      ).then((res) => {
        window.open(res.data);
      });
    },
    // 是否发放学员
    ynffxy(row) {
      this.$post(
        "/biz/card/key/modifySend",
        {
          keyId: row.keyId,
          send: row.send,
        },
        3000,
        true,
        2
      ).then((res) => {
        if (res.status == 0) {
          this.getData();
          this.$message({
            message: "设置成功!",
            type: "success",
          });
        }
      });
    },
    // 导出 - 卡面
    hExportkmian(row) {
      const params = {
        keyId: row.keyId,
      };
      this.$post("/biz/card/wallet/downloadCard", params, 3000, true, 2).then(
        (res) => {
          window.open(res.data);
        }
      );
    },
    // 绑定用户 - 弹框
    bdPhone(row) {
      this.temporaryData.keyId = row.keyId;
      this.temporaryData.walletId = row.walletId;
      this.dialogVisible1 = true;
    },
    // 绑定用户 - 弹框 - 确定
    bdPhoneYes(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$post(
            "/biz/card/wallet/bindUserKey",
            {
              courseId: this.courseId,
              orderId: this.orderId,
              mobile: this.tradeSet.bdPhone,
              keyId: this.temporaryData.keyId,
              walletId: this.temporaryData.walletId,
            },
            3000,
            true,
            2
          ).then((res) => {
            if (res.status == 0) {
              this.tradeSet.bdPhone = "";
              this.dialogVisible1 = false;
              this.$message({
                message: "绑定成功!",
                type: "success",
              });
            }
          });
        }
      });
    },
    // 绑定用户 - 弹框 - 关闭
    bdPhoneNo() {
      this.tradeSet.bdPhone = "";
      this.dialogVisible1 = false;
    },
  },
};
</script>
<style lang="less">
.remark {
  margin-left: 1em;
  color: red;
}
</style>
